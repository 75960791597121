<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-card>
                  <v-data-table
                    :headers="TransitHeader"
                    :items="orders"
                    :search="search"
                    :loading="loading"
                    loading-text="Please wait..."
                    item-key="id"
                    v-model="selected"
                    :single-select="singleSelect"
                    show-select
                    class="elevation-1"
                    style="background: #f5f5f5"
                    return-object
                  >
                    <template v-slot:top>
                      <v-card-title style="font-size: 26px">
                        All Products
                        <v-spacer></v-spacer>
                        <div class="pr-5 pb-5 pt-2">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            filled
                            rounded
                            dense
                            hide-details
                          ></v-text-field>
                        </div>
                        <div>
                          <v-btn color="primary" @click="savetransit(item)">
                            Save
                          </v-btn>
                        </div>
                      </v-card-title>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        v-if="item.admin_status == 'Confirmed'"
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewInvoice(item)"
                        >Invoice</v-btn
                      >
                    </template>
                    <template
                      v-slot:item.actions="{ item }"
                      style="max-width: 30px"
                    >
                      <v-text-field
                        v-model="item.quantity"
                        outlined
                        dense
                        class="mt-5"
                      ></v-text-field>
                    </template>

                    <template v-slot:item.motherinvoice="{ item }">
                      <v-btn @click="viewMotherInvoice(item)" text>
                        <v-icon
                          dark
                          class="pr-5"
                          style="margin-left: 0 !important"
                        >
                          mdi-eye-outline</v-icon
                        >
                        {{ item.reference_id }}
                      </v-btn>
                    </template>

                    <template v-slot:item.admin_status="{ item }">
                      <v-chip :color="getColor(item.admin_status)" dark>
                        {{ item.admin_status }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-card-text></v-row
      >

      <v-dialog v-model="Transfersavedialog" max-width="700px">
        <v-card>
          <v-toolbar elevation="2" class="mb-5 center">
            <p class="text-center mb-0">Please Confirm Your Product</p>
          </v-toolbar>
          <v-data-table
            :headers="dessertHeaders"
            :items="selected"
            item-key="id"
            v-model="selected"
            :single-select="singleSelect"
            show-select
            class="elevation-1"
            style="background: #f5f5f5"
            return-object
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.actions="{ item }" style="max-width: 30px">
              <v-text-field
                v-model="item.quantity"
                outlined
                dense
                class="mt-5"
              ></v-text-field>
            </template>
          </v-data-table>
          <v-card-actions class="py-5">
            <v-spacer></v-spacer>

            <v-btn elevation="0" color="red" @click="CancelInvoice()">
              Cancel
            </v-btn>
            <v-btn @click="SaveApprove(item)" elevation="0" color="success">
              Create Invoice
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    
    
    
    </v-container
  >
</template>


<script>
export default {
  data: () => ({
    expanded: [],
    search: "",
    selected: [],
    Transfersavedialog: false,
    singleExpand: false,
    loading: true,
    singleSelect: false,
    dessertHeaders: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Invoice ID", value: "invoice_id", sortable: false },

      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
      { text: "Delivery Status", value: "delivery_status", sortable: false },
      { text: "Quantity", value: "actions", sortable: false },
    ],
    TransitHeader: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Date", value: "ordered_date", sortable: false },
      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },

      { text: "Delivery Status", value: "delivery_status", sortable: false },
      { text: "Quantity", value: "actions", sortable: false },
    ],
    showItem: {},

    tickets: [],

    orders: [],
    show: false,
    today_order: 0,
    total_customer: 0,
    total_seller: 0,
    total_staff: 0,

    series: [44, 55, 13],
    chartOptions: {
      chart: {
        width: 280,
        type: "pie",
        offsetX: 60,
      },
      labels: ["Delivered", "Pending", "Cancelled"],
      colors: ["#4CAF50", "#EFCE62", "#F94B4B"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      legend: {
        position: "left",
        offsetY: 25,
        offsetX: -30,
        height: 630,
        itemMargin: {
          vertical: 20,
        },
      },
    },
  }),

  methods: {
    initialize() {
      axios.get("Cart/orders/").then((response) => {
        this.orders = response.data.data;
        this.loading = false;
        // console.log("this is order", this.orders);
      });

      axios.get("supports/dashboard/").then((response) => {
        this.today_order = response.data.data.orders;
        this.total_customer = response.data.data.total_customers;
        this.total_seller = response.data.data.total_sellers;
        this.total_staff = response.data.data.total_staff;
      });
    },

    savetransit(selected) {
      this.Transfersavedialog = true;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>
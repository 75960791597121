var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-row',[_c('v-card-text',{staticStyle:{"background":"white","height":"100%"}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',[[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.TransitHeader,"items":_vm.orders,"search":_vm.search,"loading":_vm.loading,"loading-text":"Please wait...","item-key":"id","single-select":_vm.singleSelect,"show-select":"","return-object":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{staticStyle:{"font-size":"26px"}},[_vm._v(" All Products "),_c('v-spacer'),_c('div',{staticClass:"pr-5 pb-5 pt-2"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.savetransit(_vm.item)}}},[_vm._v(" Save ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-5",attrs:{"outlined":"","dense":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.motherinvoice",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.viewMotherInvoice(item)}}},[_c('v-icon',{staticClass:"pr-5",staticStyle:{"margin-left":"0 !important"},attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" "+_vm._s(item.reference_id)+" ")],1)]}},{key:"item.admin_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.admin_status),"dark":""}},[_vm._v(" "+_vm._s(item.admin_status)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]],2)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.Transfersavedialog),callback:function ($$v) {_vm.Transfersavedialog=$$v},expression:"Transfersavedialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-5 center",attrs:{"elevation":"2"}},[_c('p',{staticClass:"text-center mb-0"},[_vm._v("Please Confirm Your Product")])]),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.selected,"item-key":"id","single-select":_vm.singleSelect,"show-select":"","return-object":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-5",attrs:{"outlined":"","dense":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-card-actions',{staticClass:"py-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0","color":"red"},on:{"click":function($event){return _vm.CancelInvoice()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"elevation":"0","color":"success"},on:{"click":function($event){return _vm.SaveApprove(_vm.item)}}},[_vm._v(" Create Invoice ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }